<template>
  <layout
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div class="expande-horizontal">
      <v-flex xs12 md3>
        <Filtros />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-produtos'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="pa-3" v-else xs12>
          <v-list class="pa-0 ma-0">
            <template v-for="(produto, index) in get_produtos.docs">
              <v-list-item
                :class="{
                  'item-selected':
                    get_produto._id === produto._id ? true : false
                }"
                @click="abrirProduto(produto, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="produto._id"
              >
                <v-list-item-content>
                  <v-list-item-title class="fonte-subtitulo">
                    {{ produto.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte-subtitulo">
                    {{ produto.preco_multiplo.length }} produtos aqui
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="animate__animated animate__fadeIn"
                    v-if="get_produto._id === produto._id"
                    icon
                  >
                    <v-icon color="white"> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_produtos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <img style="width: 80%" src="img/empty.gif" alt="empty" />
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Não encontramos nenhuma categoria, clique no botão abaixo e
              cadastre uma!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Nova Categoria
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
        <v-flex v-if="get_produtos.docs.length" class="pa-3" xs12>
          <Paginacao />
        </v-flex>
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9>
        <div
          v-if="get_produto.new || get_produto._id"
          class="expande-horizontal wrap"
        >
          <!-- Header da categoria -->
          <v-flex xs12>
            <div class="expande-horizontal fonte">
              <v-list-item class="pa-0 ma-0 pr-3">
                <v-avatar color="#47CF72" class="mr-2" icon>
                  <v-icon color="white">mdi-family-tree</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{
                      get_produto.new
                        ? get_produto.nome
                          ? get_produto.nome
                          : "Nova Categoria"
                        : get_produto.nome
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-mini">
                    Gerencie os detalhes abaixo
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="get_produto._id">
                  <v-btn
                    color="red"
                    @click="excluir_categoria(get_produto)"
                    outlined
                    small
                    >Excluir</v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-flex>
          <!-- Dados da categoria form -->
          <v-flex xs12>
            <v-form ref="form">
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <v-flex class="pr-3" xs12 md12>
                    <v-text-field
                      label="Nome"
                      dense
                      outlined
                      class="fonte-subtitulo"
                      placeholder="ex: Pizzas"
                      v-model="get_produto.nome"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md12>
                    <v-text-field
                      label="Descrição"
                      dense
                      outlined
                      class="fonte-subtitulo"
                      placeholder="ex: Pizzas premium"
                      v-model="get_produto.descricao"
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <!-- Botão de salvar a categoria -->
          <v-flex xs12 class="pb-6">
            <div class="expande-horizontal">
              <v-flex xs12 md3>
                <v-btn
                  @click="criarOuAtualizar"
                  color="#47CF72"
                  dark
                  outlined
                  class="fonte-subtitulo text-capitalize"
                >
                  Salvar Categoria
                </v-btn>
              </v-flex>
            </div>
          </v-flex>
          <!-- Listagem dos produtos -->
          <v-flex xs12>
            <div class="expande-horizontal wrap">
              <v-flex xs12>
                <v-list-item class="fonte pa-0 ma-0">
                  <v-avatar color="#47CF72" class="mr-2" icon>
                    <v-icon color="white">mdi-basket</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Produtos
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os produtos abaixo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <v-flex xs12>
                    <div
                      v-if="get_produto.preco_multiplo.length"
                      class="pb-6 expande-horizontal pr-3"
                    >
                      <v-list style="width: 100%;">
                        <template
                          v-for="(tamanho, index) in get_produto.preco_multiplo"
                        >
                          <v-list-item
                            style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                            class="animate__animated animate__fadeInUp"
                            :key="index"
                          >
                            <v-switch
                              :color="$theme.third"
                              v-model="tamanho.disponivel"
                              @change="atualizar_produto"
                            ></v-switch>
                            <v-list-item-content>
                              <v-list-item-title
                                class="fonte-italica fonte-subtitulo"
                              >
                                {{ tamanho.nome }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="grey--text fonte-subtitulo fonte-italica"
                              >
                                {{ tamanho.descricao }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="green--text font-weight-bold fonte-subtitulo fonte-italica"
                              >
                                {{
                                  $helper.formataSaldo(
                                    tamanho.preco_com_markup
                                      ? tamanho.preco_com_markup
                                      : tamanho.preco_com_lp
                                  )
                                }}
                                <!-- <v-btn
                                  v-if="index === precoFichaTecnicaIndex"
                                  class="ml-3 mb-1 fonte fonte-bold"
                                  dark
                                  color="green"
                                  x-small
                                >
                                  Aberto recentemente
                                </v-btn> -->
                                <v-btn
                                  @click="
                                    iniciarConfiguracaoFichaTecnica(
                                      tamanho,
                                      index
                                    )
                                  "
                                  class="ml-3 mb-1 fonte fonte-bold"
                                  dark
                                  :color="$theme.third"
                                  x-small
                                >
                                  Configurar preço
                                </v-btn>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                @click="removePrecoMultiplo(index)"
                                small
                                icon
                              >
                                <v-icon size="16">mdi-delete-outline</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                    <div
                      v-else
                      class="expande-horizontal fonte-default fonte-little"
                    >
                      Cadastre seu primeiro produto
                    </div>
                  </v-flex>
                  <v-flex
                    v-if="get_produto.preco_multiplo.length && !showFormPreco"
                    xs12
                  >
                    <v-btn
                      small
                      dark
                      outlined
                      class="fonte-subtitulo mb-3"
                      color="#47CF72"
                      @click="showFormPreco = true"
                    >
                      Adicionar mais um produto
                      <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex class="mb-6" v-else x12>
                    <v-form
                      style="border: 1px solid #47CF72; border-radius: 6px;"
                      class="pa-2"
                      ref="formNewPrecoMultiplo"
                    >
                      <v-flex xs12>
                        <div class="expande-horizontal wrap">
                          <v-flex class="pr-3" xs12 md4>
                            <span class="fonte-italica fonte-subtitulo">
                              Nome
                            </span>
                            <v-text-field
                              solo
                              flat
                              dense
                              class="fonte-subtitulo fonte-italica"
                              background-color="#f2f2f2"
                              placeholder="ex: G"
                              v-model="formPrecoMultiplo.nome"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="pr-3" xs12 md4>
                            <span class="fonte-italica fonte-subtitulo">
                              Descrição
                            </span>
                            <v-text-field
                              solo
                              flat
                              dense
                              class="fonte-subtitulo fonte-italica"
                              background-color="#f2f2f2"
                              placeholder="ex: Tamanho G"
                              v-model="formPrecoMultiplo.descricao"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="pr-6" xs12>
                            <span class="fonte-italica fonte-subtitulo">
                              Disponibilidade
                            </span>
                            <v-switch
                              dense
                              class="fonte-subtitulo fonte-italica"
                              v-model="formPrecoMultiplo.disponivel"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs12>
                            <div class="expande-horizontal wrap">
                              <v-flex class="pa-1" xs12 md3>
                                <v-btn
                                  @click="adicionarNovoPreco"
                                  color="#47cf72"
                                  dark
                                  block
                                >
                                  <span class="fonte-subtitulo">
                                    Cadastrar e precificar
                                  </span>
                                  <v-icon size="18"
                                    >mdi-check-circle-outline</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                              <v-flex class="pa-1" xs12 md3>
                                <v-btn
                                  v-if="get_produto.preco_multiplo.length"
                                  @click="showFormPreco = false"
                                  block
                                  dark
                                  class="fonte-subtitulo text-capitalize"
                                  text
                                  color="#cf4747"
                                  >Cancelar
                                  <v-icon size="18">mdi-close</v-icon></v-btn
                                >
                              </v-flex>
                            </div>
                          </v-flex>
                        </div>
                      </v-flex>
                    </v-form>
                  </v-flex>
                </div>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </v-flex>
      <v-dialog
        transition="slide-x-transition"
        fullscreen
        :value="get_produto._id || get_produto.new ? true : false"
        v-else
      >
        <v-card class="pl-3">
          <div
            v-if="get_produto.new || get_produto._id"
            class="expande-horizontal wrap"
          >
            <v-flex xs12>
              <div class="expande-horizontal fonte">
                <v-list-item class="pa-0 ma-0">
                  <v-btn @click="backToList" class="mr-2" icon>
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{
                        get_produto.new
                          ? get_produto.nome
                            ? get_produto.nome
                            : "Nova Categoria"
                          : get_produto.nome
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes abaixo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex class="pl-2" xs12>
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pr-3" xs12 md12>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas"
                        v-model="get_produto.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md12>
                      <v-text-field
                        label="Descrição"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas premium"
                        v-model="get_produto.descricao"
                      ></v-text-field>
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <v-flex xs12 class="pb-6 px-2">
              <div class="expande-horizontal">
                <v-flex xs12 md3>
                  <v-btn
                    @click="criarOuAtualizar"
                    color="#47CF72"
                    dark
                    block
                    outlined
                    class="fonte-subtitulo text-capitalize"
                  >
                    Salvar Categoria
                  </v-btn>
                </v-flex>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal wrap">
                <v-flex class="pl-2" xs12>
                  <v-list-item class="fonte pa-0 ma-0">
                    <v-avatar color="#47CF72" class="mr-2" icon>
                      <v-icon color="white">mdi-basket</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        Produtos
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        Gerencie os produtos abaixo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex class="pl-2" xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <div
                        v-if="get_produto.preco_multiplo.length"
                        class="pb-6 expande-horizontal pr-3"
                      >
                        <v-list style="width: 100%;">
                          <template
                            v-for="(tamanho,
                            index) in get_produto.preco_multiplo"
                          >
                            <v-list-item
                              style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                              class="animate__animated animate__fadeInUp"
                              :key="index"
                            >
                              <v-switch
                                color="#47cf72"
                                v-model="tamanho.disponivel"
                                @change="atualizar_produto"
                              ></v-switch>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="fonte-italica fonte-subtitulo"
                                >
                                  {{ tamanho.nome }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="grey--text fonte-subtitulo fonte-italica"
                                >
                                  {{ tamanho.descricao }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="green--text font-weight-bold fonte-subtitulo fonte-italica"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      tamanho.preco_com_markup
                                        ? tamanho.preco_com_markup
                                        : tamanho.preco_com_lp
                                    )
                                  }}
                                  <!-- <v-btn
                                    v-if="index === precoFichaTecnicaIndex"
                                    class="ml-3 mb-1 fonte fonte-bold"
                                    dark
                                    color="green"
                                    x-small
                                  >
                                    Aberto recentemente
                                  </v-btn> -->
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <v-btn
                                    @click="
                                      iniciarConfiguracaoFichaTecnica(
                                        tamanho,
                                        index
                                      )
                                    "
                                    class="ml-3 mb-1 fonte fonte-bold"
                                    dark
                                    color="#47CF72"
                                    x-small
                                  >
                                    Configurar preço
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn
                                  @click="removePrecoMultiplo(index)"
                                  small
                                  icon
                                >
                                  <v-icon size="16">mdi-delete-outline</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                      <div
                        v-else
                        class="expande-horizontal fonte-default fonte-little"
                      >
                        Cadastre seu primeiro produto
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="get_produto.preco_multiplo.length && !showFormPreco"
                      xs12
                      class="pr-2"
                    >
                      <v-btn
                        dark
                        class="fonte-subtitulo mb-3"
                        color="#47CF72"
                        block
                        @click="showFormPreco = true"
                      >
                        Adicionar mais um produto
                        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex class="mb-6 pr-2" v-else x12>
                      <v-form
                        style="border: 1px solid #47cf72; border-radius: 6px;"
                        class="pa-2"
                        ref="formNewPrecoMultiplo"
                      >
                        <v-flex xs12>
                          <div class="expande-horizontal wrap">
                            <v-flex class="pr-3" xs12 md4>
                              <span class="fonte-italica fonte-subtitulo">
                                Nome
                              </span>
                              <v-text-field
                                solo
                                flat
                                dense
                                class="fonte-subtitulo fonte-italica"
                                background-color="#f2f2f2"
                                placeholder="ex: G"
                                v-model="formPrecoMultiplo.nome"
                              ></v-text-field>
                            </v-flex>
                            <v-flex class="pr-3" xs12 md4>
                              <span class="fonte-italica fonte-subtitulo">
                                Descrição
                              </span>
                              <v-text-field
                                solo
                                flat
                                dense
                                class="fonte-subtitulo fonte-italica"
                                background-color="#f2f2f2"
                                placeholder="ex: Tamanho G"
                                v-model="formPrecoMultiplo.descricao"
                              ></v-text-field>
                            </v-flex>
                            <v-flex class="pr-6" xs12>
                              <span class="fonte-italica fonte-subtitulo">
                                Disponibilidade
                              </span>
                              <v-switch
                                dense
                                color="#47cf72"
                                class="fonte-subtitulo fonte-italica"
                                v-model="formPrecoMultiplo.disponivel"
                              ></v-switch>
                            </v-flex>
                            <v-flex xs12>
                              <div class="expande-horizontal wrap">
                                <v-flex class="pa-1" xs12 md6>
                                  <v-btn
                                    v-if="get_produto.preco_multiplo.length"
                                    @click="showFormPreco = false"
                                    block
                                    dark
                                    class="fonte-subtitulo text-capitalize"
                                    text
                                    color="#cf4747"
                                    >Cancelar
                                    <v-icon size="18">mdi-close</v-icon></v-btn
                                  >
                                </v-flex>
                                <v-flex class="pa-1" xs12 md6>
                                  <v-btn
                                    @click="adicionarNovoPreco"
                                    color="#47cf72"
                                    dark
                                    block
                                  >
                                    <span class="fonte-subtitulo">
                                      Cadastrar e precificar
                                    </span>
                                    <v-icon size="18"
                                      >mdi-check-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </v-flex>
                              </div>
                            </v-flex>
                          </div>
                        </v-flex>
                      </v-form>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <ModalConfigProduct
      ref="modalConfigProduct"
      :product="productToSetup"
      :save="saveProduct"
    />
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import InsumosFiltro from "../../Insumos/components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import ModalConfigProduct from "../components/modalConfigProduct.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveById from "../../shared/components/ModalSendById.vue";
export default {
  data() {
    return {
      card: false,
      productToSetup: false,
      showFormPreco: false,
      contextDialog: false,
      drawer: false,
      insumo: {},
      preco_final: 0,
      options: [
        {
          nome: "Nova Categoria",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: ""
        },
        {
          name: "Categorias de Produtos",
          path: "/menuonline"
        }
      ],
      get_categoria: {
        produtos: []
      },
      showFormAdicionarInsumo: false,
      nova_secao: "",
      formPrecoMultiplo: {},
      lucro_pretendido: "",
      formInsumoDialog: false,
      showFormFichaTecnica: false,
      precoFichaTecnicaIndex: null,
      precoFichaTecnica: {}
    };
  },
  components: {
    Filtros,
    Paginacao,
    ModalConfigProduct
  },
  computed: {
    ...mapGetters([
      "get_tenants",
      "get_tenants_filtros",
      "get_insumos_filtros",
      "get_produtocategorias",
      "get_produtocategoria",
      "getLoggedUser",
      "get_produto",
      "get_insumo",
      "getLinks",
      "get_insumos",
      "get_produtos",
      "getLinksById",
      "getPersonalizedLoading",
      "get_invoices",
      "get_invoices_filtros"
    ]),
    valorTotalComBaseNosInsumos() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(val.preco_de_compra);
        const preco_da_grama = preco_de_compra / 1000;
        const total_de_entrada = quantidade * preco_da_grama;
        return total_de_entrada;
      };
      let total = 0;
      const ficha_tecnica = this.get_produto.ficha_tecnica || [];
      ficha_tecnica.map(estoque => {
        total += calcPreco(estoque);
      });

      return parseFloat(total.toFixed(2));
    }
  },
  filters: {
    calcGramaTotal(val) {
      const quantidade = parseFloat(val.quantidade || 0);
      const preco_de_compra = parseFloat(val.preco_de_compra || 0);
      const preco_da_grama =
        preco_de_compra / val.unidade_estoque_minimo.divisor;
      const total_de_entrada = quantidade * preco_da_grama;
      return total_de_entrada.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  methods: {
    saveProduct() {
      this.$refs.modalConfigProduct.closeModal();
      this.get_produto.preco_multiplo[
        this.precoFichaTecnicaIndex
      ].imagens = this.getLinks;
      this.criarOuAtualizar();
    },
    abrirProduto(produto) {
      this.$store.commit("set_produto", produto);
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_produto.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_produto.preco_multiplo = novoPrecoMultiplo;
      this.atualizar_produto();
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_produtos",
      "listar_insumos",
      "listar_produtocategorias",
      "criar_produto",
      "atualizar_produto",
      "setPersonalizedLoading",
      "createGlobalMessage",
      "createConfirmAction",
      "listar_invoices"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        const index = this.get_produto.preco_multiplo.length;
        this.get_produto.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
        this.iniciarConfiguracaoFichaTecnica(preco, index);
        this.showFormPreco = false;
      }
    },
    criarOuAtualizar() {
      if (this.get_produto._id) {
        this.atualizar_produto();
      } else {
        this.criar_produto();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_produto", {
        new: true,
        preco_multiplo: [],
        ficha_tecnica: []
      });
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    updateField(prod, campo, novo_valor) {
      let produto = prod;
      produto[campo] = novo_valor;
      this.$store.commit("set_produto", produto);
      this.atualizar_produto();
    },
    addProduto() {
      this.criar_produto();
    },
    backToList() {
      this.$store.commit("set_produto", {});
      this.$forceUpdate();
    },
    iniciarConfiguracaoFichaTecnica(preco, index) {
      this.productToSetup = preco;
      this.precoFichaTecnica = preco;
      this.precoFichaTecnicaIndex = index;
      // this.showFormFichaTecnica = true;
      // this.lucro_pretendido = preco.lucro_pretendido || "";
      // this.preco_final = preco.preco_final || 0;
      // this.get_produto.ficha_tecnica = preco.ficha_tecnica || [];
      this.$store.commit("setLinks", preco.imagens || []);
      this.$refs.modalConfigProduct.open();
    },
    excluir_categoria(item) {
      let text = item.preco_multiplo.length
        ? `Há ${item.preco_multiplo.length} produtos cadastrados, deseja realmente excluir?`
        : "Confirma exclusão?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_produto",
        action_value: item
      });
    }
  },
  created() {
    this.listar_produtos();
    this.listar_insumos();
    this.listar_invoices();
  }
};
</script>

<style scoped>
.item-selected {
  background: #010f50 !important;
  color: #fff !important;
}
</style>
